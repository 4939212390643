import React, { useState, useRef, useEffect } from "react"
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import classNames from "classnames/bind"
import useYoutubeScript from "../hooks/useYoutubeScript"

function VideoModal({ open, onCloseModal, videoId = "dwe93OWmXOY" }) {
  // videoId = "344814275"
  const [loaded] = useYoutubeScript("sample-youtube")

  const [playerState, setPlayerState] = useState()
  const player = useRef(null)
  const isPlayerReady = useRef(false)

  useEffect(() => {
    const playerObject = player.current
    const hasYoutubeInWindow =
      typeof window !== "undefined" &&
      typeof window.YT === "object" &&
      window.YT.Player
    const isPlayerAlreadySet = player && player.current !== null

    if (loaded && hasYoutubeInWindow && !isPlayerAlreadySet && open) {
      setTimeout(() => {
        player.current = new window.YT.Player(`youtube-iframe-${videoId}`, {
          videoId,
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
          },
          playerVars: {
            autoplay: 1,
            loop: 0,
            controls: 1,
            showinfo: 0,
            autohide: 1,
            modestbranding: 1,
          },
        })
      }, 100)
    }
    return () => {
      if (!open) {
        playerObject && playerObject.destroy && playerObject.destroy()
        player.current = null
      }
    }
  }, [loaded, open, videoId])

  const onPlayerReady = () => {
    isPlayerReady.current = true
  }

  const onPlayerStateChange = event => {
    setPlayerState(event.data)
  }

  const playAgain = event => {
    if (
      isPlayerReady &&
      typeof window !== "undefined" &&
      playerState === window.YT.PlayerState.ENDED
    ) {
      player.current.seekTo(0)
    } else if (
      isPlayerReady &&
      typeof window !== "undefined" &&
      playerState === window.YT.PlayerState.PAUSED
    ) {
      player.current.playVideo()
    }
  }

  const playerWrapperClass = classNames({
    "embed-responsive-item youtubeWrapper": true,
    ended:
      typeof window !== "undefined" &&
      typeof window.YT === "object" &&
      window.YT.Player &&
      playerState === window.YT.PlayerState.ENDED,
    paused:
      typeof window !== "undefined" &&
      typeof window.YT === "object" &&
      window.YT.Player &&
      playerState === window.YT.PlayerState.PAUSED,
  })

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      classNames={{
        overlay: "customOverlay",
        modal: "customModal",
      }}
    >
      <div className="embed-responsive embed-responsive-16by9">
        <button
          type="button"
          className={playerWrapperClass}
          onClick={event => playAgain(event)}
        >
          <div id={`youtube-iframe-${videoId}`} className="video" />
        </button>
      </div>
    </Modal>
  )
}

VideoModal.defaultProps = {}

VideoModal.propTypes = {}

export default VideoModal
